<template>
  <div class="">
    <clinician-team-form
      v-if="loaded"
      :action="action"
      :team="team"
      @submitTeam="submitTeam"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClinicianTeamForm from '@/components/clinician/teams/ClinicianTeamForm'
import { GET_ALL_CLINICIANS } from '@/store/modules/clinicians/constants.js'

export default {
  name: 'ClinicianTeamFormWrapper',
  components: {
    ClinicianTeamForm
  },
  data: function () {
    return {
      loaded: false
    }
  },
  computed: {
    action () {
      return this.$route.params.teamId ? 'update' : 'create'
    },
    ...mapGetters({
      team: 'getSingleClinicianTeam'
    })
  },
  created () {
    const loader = this.$loading.show()
    const promises = [this.$store.dispatch(GET_ALL_CLINICIANS)]
    promises.push(this.$store.dispatch('GET_CLINICIAN_TEAM', this.$route.params.teamId))
    Promise.all(promises).then(() => {
      this.loaded = true
    }).catch((e) => {
      this.$handleApiError(e)
    }).finally(() => {
      loader.hide()
    })
  },
  methods: {
    cancel () {
      if (this.$parent.beforeClose) {
        this.$parent.beforeClose()
      }
    },
    submitTeam (team) {
      const action = this.action === 'update' ? 'UPDATE_CLINICIAN_TEAM' : 'ADD_CLINICIAN_TEAM'
      const messageIndex = this.action === 'update' ? 'fieldUpdated' : 'fieldAdded'
      const loader = this.$loading.show()
      const $validator = this.$validator
      this.$store.dispatch(action, team)
        .then(response => {
          if (response && response.status !== 200) {
            this.$handleApiError(response)
            Object.keys(response.data.errors).forEach(function (field) {
              $validator.errors.add(field, response.data.errors[field])
            })
          } else {
            this.$router.push({ name: 'SettingsClinicalTeam' })
            this.$toast.success({ message: this.$t(messageIndex, { name: team.name }) })
          }
        })
        .catch((e) => {
          this.$handleApiError(e)
        })
        .finally(() => {
          loader.hide()
        })
    }
  }
}
</script>
