<template>
  <div class="multi-select-list-item">
    {{ clinician.name }}
    <span @click="requireDeleteDialog ? deleteDialog = true : onDelete()">{{ $t('remove') }}</span>
    <delete-dialog
      v-if="deleteDialog"
      key="clinician.id"
      :confirm-btn="$t('yesRemove')"
      :cancel-btn="$t('noResume')"
      :title="$t('deleteClinicianFromTeamTitle')"
      @onDelete="onDelete"
      @onCancel="onCancel"
    />
  </div>
</template>

<script>
import DeleteDialog from '@/components/common/DeleteDialog'
export default {
  name: 'CliniciansAssigned',
  components: {
    DeleteDialog
  },
  props: {
    clinician: Object, // eslint-disable-line
    requireDeleteDialog: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      deleteDialog: false
    }
  },
  methods: {
    onDelete () {
      this.deleteDialog = false
      this.$emit('onDelete', this.clinician.id)
    },
    onCancel () {
      this.deleteDialog = false
      this.$emit('onCancel')
    }
  }
}
</script>
