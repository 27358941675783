<template>
  <div class="clinician-team-form">
    <div>
      <h3>{{ $t(title) }}</h3>
      <form
        autocomplete="off"
        @submit.prevent="save"
      >
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="mrn">{{ $t('name') }}</label>
              <input
                v-model="teamForm.name"
                v-validate="'required|uniqueTeamName'"
                type="text"
                name="name"
                class="form-control"
                :data-vv-as="$t('name')"
                data-vv-validate-on="blur"
                placeholder="-"
              >
              <span
                v-show="errors.has('name')"
                class="error"
              >{{ errors.first('name') }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="multi-select-list">
              <label for="mrn">{{ $t('clinicians') }}</label>
              <clinicians-assigned
                v-for="clinician in teamClinicians"
                :key="clinician.id"
                :clinician="clinician"
                :require-delete-dialog="clinician.requireDeleteDialog != undefined ? clinician.requireDeleteDialog : true"
                @onDelete="removeClinician"
              />
            </div>
            <div class="form-group">
              <multiselect
                :options="clinicianList"
                :multiple="true"
                :clear-on-select="true"
                :searchable="true"
                :preserve-search="currentBrowser.isExplorer"
                :show-labels="false"
                :hide-selected="true"
                :placeholder="$t('select')"
                label="name"
                @select="addClinician"
              />
              <input
                v-model="teamClinicians"
                v-validate="'required'"
                type="hidden"
                name="clinicians"
                :data-vv-as="$t('atLeast1clinician')"
              >
              <span
                v-show="errors.has('clinicians')"
                class="error"
              >{{ errors.first('clinicians') }}</span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="buttons">
      <button
        type="button"
        class="btn btn-outline-secondary"
        @click="cancel"
      >
        {{ $t('cancel') }}
      </button>
      <button
        type="button"
        :disabled="!isDoneEnabled"
        class="btn btn-secondary"
        @click="save"
      >
        {{ $t('saveAndClose') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import CliniciansAssigned from './CliniciansAssigned'

export default {
  name: 'ClinicianTeamForm',
  components: {
    Multiselect,
    CliniciansAssigned
  },
  props: ['action', 'team'],
  data () {
    return {
      teamForm: Object.assign({}, this.team),
      teamClinicians: [],
      submitState: false
    }
  },
  computed: {
    ...mapGetters({
      currentBrowser: 'getCurrentBrowser',
      clinicians: 'getClinicians'
    }),
    cliniciansIds () {
      return this.teamClinicians.map(item => item.id)
    },
    clinicianList () {
      return this.clinicians.filter(item => this.cliniciansIds.indexOf(item.id) === -1)
    },
    isDoneEnabled () {
      if (this.submitState) {
        return false
      }

      return (this.teamForm.name && this.teamClinicians.length)
    },
    title () {
      return `${this.action}ClinicianTeam`
    },
    computedForm () {
      return Object.assign({}, this.teamForm)
    }
  },
  watch: {
    computedForm: {
      handler (n, o) {
        this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', Object.keys(this.teamForm).some(field => n[field] !== o[field]))
      },
      deep: true
    },
    cliniciansIds (n, o) {
      if ((this.team && o.length) || !this.team) {
        this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', n.ength !== o.length)
      }
    },
    deep: true
  },
  created () {
    this.setTeamClinicians()
  },
  destroyed () {
    this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
  },
  beforeMount () {
    const uniqueTeamName = () => {
      return new Promise(resolve => {
        this.$store.dispatch('VALIDATE_TEAM_NAME', this.teamForm).then((data) => {
          if (!data.available) {
            return resolve({
              valid: false,
              data: {
                message: this.$t('teamNameInUse')
              }
            })
          }

          return resolve({
            valid: true
          })
        }).catch(() => {
          return resolve({
            valid: false,
            data: {
              message: this.$t('generalError')
            }
          })
        })
      })
    }

    this.$validator.extend('uniqueTeamName', {
      validate: uniqueTeamName,
      getMessage: (field, params, data) => data.message
    })
  },
  methods: {
    setTeamClinicians () {
      if (!this.team) {
        return
      }
      const cliniciansIds = this.team.active_clinicians.map(item => parseInt(item.id))
      this.teamClinicians = this.clinicians.filter(item => cliniciansIds.indexOf(parseInt(item.id)) !== -1)
      this.teamClinicians = this.teamClinicians.sort((a, b) => a.name.localeCompare(b.name))
    },
    addClinician (item) {
      item.requireDeleteDialog = false
      this.teamClinicians.push(item)
    },
    removeClinician (id) {
      this.teamClinicians = this.teamClinicians.filter(item => item.id !== id)
    },
    cancel () {
      this.$emit('cancel')
    },
    save () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.submitState = true
          const data = {
            name: this.teamForm.name,
            clinicians: this.cliniciansIds
          }
          if (this.teamForm.id) {
            data.id = this.teamForm.id
          }
          this.$emit('submitTeam', data)
        }
      })
    }
  }
}
</script>
