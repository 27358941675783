<template>
  <div
    class="delete-dialog"
    :class="{ alert: isAlertDialog }"
  >
    <div class="text-container">
      <strong class="title">
        {{ title }}
      </strong>
      <p class="dialog">
        {{ body }}
      </p>
    </div>
    <div class="footer">
      <button
        type="button"
        class="btn btn-square btn-cancel"
        @click.stop="$emit('onCancel')"
      >
        {{ cancelBtnText }}
      </button>
      <button
        type="button"
        class="btn btn-square btn-delete"
        @click.stop="$emit('onDelete')"
      >
        {{ confirmBtnText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteDialog',
  props: ['title', 'body', 'confirmBtn', 'cancelBtn'], // eslint-disable-line vue/require-prop-types
  computed: {
    confirmBtnText () {
      return this.confirmBtn || this.$t('delete')
    },
    cancelBtnText () {
      return this.cancelBtn || this.$t('cancel')
    },
    isAlertDialog () {
      return this.title === this.$t('alertDeleteTitle')
    }
  }
}
</script>
<style scoped lang="scss">
  .delete-dialog {
    position: absolute;
    width: 400px;
    min-height: 215px;
    padding: 20px;
    z-index: 999;
    background: #FFFFFF;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(10, 53, 96, 0.55);
    right: 20px;
    margin-top: -45px;
    &.alert {
      margin-top: -215px;
    }
    .text-container {
      min-height: 145px;
      min-width: 360px;
      margin: 0 !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #0a3560;
      text-align: left;
    }
    .title {
      margin-bottom: 22px;
      font-weight: 700;
      color: inherit;
      font-size: 16pt;
      line-height: 120%;
    }
    .dialog {
      font-weight: 500;
      color: inherit;
      margin-bottom: 0;
      color: inherit;
      margin-bottom: 5px;
      font-size: 16pt;
      line-height: 120%;
    }

    .footer {
      text-align: right;
      button {
        font-family: MuseoSans;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: center;
        border-radius: 0;
        padding: 0.075rem 1.35rem;
        color: white;
        text-transform: uppercase;
      }
    }

    .btn-delete {
      background-color: #ED616A;
    }
    .btn-cancel {
      background-color: #b3c1d1;
      color: white;
      margin-right: 10px;
    }
  }
</style>
